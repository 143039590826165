export const DEVICE_STATUS = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
    EXPIRED: 'expired',
    INACTIVE: 'inactive'
};

export const DEVICE_STATUSES = {
    [DEVICE_STATUS.ENABLED]: {
        background: 'rgba(45, 143, 87, .1)',
        border: 'rgba(45, 143, 87, 1)',
        color: 'rgba(45, 143, 87, 1)'
    },
    [DEVICE_STATUS.INACTIVE]: {
        background: 'rgba(255, 192, 0, .1)',
        border: 'rgba(255, 192, 0, 1)',
        color: 'rgba(255, 192, 0, 1)'
    },
    [DEVICE_STATUS.EXPIRED]: {
        background: 'rgba(255, 0, 0, .1)',
        border: 'rgba(255, 0, 0, 1)',
        color: 'rgba(255, 0, 0, 1)'
    },
    [DEVICE_STATUS.DISABLED]: {
        background: 'rgba(128, 128, 128, .1)',
        border: 'rgba(128, 128, 128, 1)',
        color: 'rgba(128, 128, 128, 1)'
    }
};
