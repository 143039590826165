import React, { useEffect, useState } from 'react';
// material-ui
import { useDropzone } from 'react-dropzone';
import {
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Grid,
    Button,
    Box,
    Typography,
    CircularProgress,
    List,
    ListItem
} from '@mui/material';
import { DownloadOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/Helpers';

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const ImportCsvModal = ({
    onClose,
    onImport,
    onDownloadClick,
    templateUrl,
    processedImports,
    loading,
    failedImport,
    collection,
    resetImport,
    requiredFields
}) => {
    const [downloadLink, setDownloadLink] = useState(null);

    useEffect(() => {
        if (templateUrl && downloadLink) {
            const link = document.createElement('a');
            link.href = templateUrl;
            link.download = `${collection}-template.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadLink(null);
        }
    }, [templateUrl, downloadLink, collection]);

    const onDrop = acceptedFiles => {
        onImport(acceptedFiles[0]);
    };

    const handleDownloadTemplate = () => {
        setDownloadLink(true);
        onDownloadClick();
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.csv'] },
        multiple: false
    });

    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        zIndex: 1000,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                >
                    <CircularProgress
                        size={60}
                        thickness={4}
                        sx={{
                            color: 'secondary.lighter'
                        }}
                    />
                </Box>
            )}
            <DialogTitle>Import {capitalizeFirstLetter(collection)}</DialogTitle>
            <Divider />
            <DialogContent
                sx={{ p: 2.5, minHeight: 360, display: 'flex', flexDirection: 'column' }}
            >
                {!processedImports && !failedImport ? (
                    <>
                        <Typography>
                            Import and bulk create {collection}. The CSV file must contain
                            the following required fields: {requiredFields}. You can
                            download the template to fill in {collection}.
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<DownloadOutlined />}
                            onClick={handleDownloadTemplate}
                            sx={{ mt: 2, alignSelf: 'flex-start' }}
                        >
                            Download Template
                        </Button>
                        <Box
                            {...getRootProps()}
                            sx={{
                                border: '2px dashed',
                                borderColor: 'grey.400',
                                borderRadius: 2,
                                padding: 2,
                                textAlign: 'center',
                                cursor: 'pointer',
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3
                            }}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <Typography>Drop the file here...</Typography>
                            ) : (
                                <Typography>
                                    Drag and drop a CSV file here, or click to select file
                                </Typography>
                            )}
                        </Box>
                    </>
                ) : (
                    <Box>
                        <List>
                            {processedImports?.length > 0 &&
                                processedImports.map(entry => (
                                    <ListItem key={entry.name}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography noWrap>
                                                    {entry.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {entry.success ? (
                                                    <Typography color="success.main">
                                                        Imported
                                                    </Typography>
                                                ) : (
                                                    <Typography color="error">
                                                        {entry.reason}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                            {Array.isArray(failedImport) &&
                                failedImport.map(entry => (
                                    <ListItem key={entry.name}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography noWrap>
                                                    {entry.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography color="error">
                                                    {entry.reason}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                            {failedImport && failedImport?.error && (
                                <Typography color="error">
                                    {failedImport?.error ||
                                        `No ${collection} were imported.`}
                                </Typography>
                            )}
                        </List>
                    </Box>
                )}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
                {(processedImports || failedImport) && (
                    <Button type="submit" variant="contained" onClick={resetImport}>
                        Import again
                    </Button>
                )}
            </DialogActions>
        </Box>
    );
};

export default ImportCsvModal;
