import React from 'react';
import { FormHelperText } from '@mui/material';

const InputFieldErrorMessage = ({ touched, error }) => {
    return (
        <FormHelperText
            sx={{
                color: '#F04134',
                marginLeft: '16px !important',
                height: '1.15rem',
                visibility: touched && error ? 'visible' : 'hidden'
            }}
        >
            {touched && error}
        </FormHelperText>
    );
};

export default InputFieldErrorMessage;
