/* eslint-disable react/no-array-index-key */
// React
import React, { useMemo, Fragment } from 'react';

// Components
import { TablePagination } from 'components/third-party/ReactTable';

// Packages
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';

// Utils
import { renderFilterTypes, DefaultColumnFilter } from 'utils/react-table';
import { DEVICE_STATUSES } from '../../utils/constants/statuses';

const DeviceTable = ({ data, handleOpenAddEditDeviceModal }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Facility Code',
                accessor: `facility_code`
            },
            {
                Header: 'Device No.',
                accessor: 'device_number',
                className: 'cell-center'
            },
            {
                Header: 'Device Type',
                accessor: 'device_type',
                className: 'cell-center'
            },
            {
                Header: 'Resident',
                accessor: 'owner_name',
                className: 'cell-center'
            },
            {
                Header: 'Access Groups',
                accessor: 'access_groups',
                className: 'cell-right',
                Cell: ({ value }) => (value ? value.join(', ') : '')
            },
            {
                Header: 'Status',
                accessor: 'status',
                className: 'cell-center',
                Cell: ({ value }) => {
                    const style = {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80%',
                        backgroundColor: DEVICE_STATUSES[value].background,
                        borderRadius: '5px',
                        border: `1px solid ${DEVICE_STATUSES[value].border}`,
                        color: DEVICE_STATUSES[value].color
                    };

                    return (
                        <div style={style}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    marginX: '10px'
                                }}
                                component="span"
                                variant="body1"
                                textTransform={'capitalize'}
                            >
                                {value}
                            </Typography>
                        </div>
                    );
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const theme = useTheme();

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const initialState = useMemo(
        () => ({
            filters: [],
            pageIndex: 0,
            pageSize: 10
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            filterTypes
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const handleClick = device => {
        handleOpenAddEditDeviceModal(device, 'edit');
    };

    return (
        <Stack sx={{ height: '100%' }}>
            <Box>
                <Table {...getTableProps()}>
                    <TableHead sx={{ borderTopWidth: 1 }}>
                        {headerGroups.map((headerGroup, index) => (
                            <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        {...column.getHeaderProps([
                                            { className: column.className },
                                            column => column.getSortByToggleProps()
                                        ])}
                                        sx={{
                                            width: 'auto'
                                        }}
                                    >
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {data && data.length === 0 ? (
                            <TableRow colSpan="12">
                                <TableCell>
                                    <Typography
                                        variant="h5"
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            textTransform: 'capitalize',
                                            width: '100%'
                                        }}
                                    >
                                        {`No devices`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {headerGroups.map((group, i) => (
                                    <TableRow key={i} {...group.getHeaderGroupProps()}>
                                        {group.headers.map((column, index) => (
                                            <TableCell
                                                key={index}
                                                {...column.getHeaderProps([
                                                    { className: column.className }
                                                ])}
                                            />
                                        ))}
                                    </TableRow>
                                ))}
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <Fragment key={i}>
                                            <TableRow
                                                {...row.getRowProps()}
                                                onClick={() => {
                                                    handleClick(row.original);
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                    textTransform: 'capitalize',
                                                    bgcolor: row.isSelected
                                                        ? alpha(
                                                              theme.palette.primary
                                                                  .lighter,
                                                              0.35
                                                          )
                                                        : 'inherit'
                                                }}
                                            >
                                                {row.cells.map((cell, i) => (
                                                    <TableCell
                                                        key={i}
                                                        {...cell.getCellProps([
                                                            {
                                                                className:
                                                                    cell.column.className
                                                            }
                                                        ])}
                                                    >
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </Fragment>
                                    );
                                })}

                                <TableRow>
                                    <TableCell sx={{ p: 2 }} colSpan={7}>
                                        <TablePagination
                                            gotoPage={gotoPage}
                                            rows={rows}
                                            setPageSize={setPageSize}
                                            pageIndex={pageIndex}
                                            pageSize={pageSize}
                                        />
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </Stack>
    );
};

export default DeviceTable;
