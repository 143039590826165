import {
    ADD_RESERVATION,
    ADD_RESERVATION_SUCCESS,
    ADD_RESERVATION_FAILURE,
    RESET_ADD_RESERVATION_STATE,
    VALIDATE_RESERVATION,
    VALIDATE_RESERVATION_SUCCESS,
    VALIDATE_RESERVATION_FAILURE,
    GET_RESERVATIONS,
    GET_RESERVATIONS_SUCCESS,
    GET_RESERVATIONS_FAILURE,
    CREATE_TENANT_ACCESS,
    CREATE_TENANT_ACCESS_FAILURE,
    CREATE_TENANT_ACCESS_SUCCESS,
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_FAILURE,
    CANCEL_RESERVATION_SUCCESS,
    REINSTATE_RESERVATION,
    REINSTATE_RESERVATION_FAILURE,
    REINSTATE_RESERVATION_SUCCESS,
    UPDATE_RESERVATION,
    UPDATE_RESERVATION_FAILURE,
    UPDATE_RESERVATION_SUCCESS,
    RESET_RESERVATION_ACTIONS_STATE,
    IMPORT_RESERVATIONS_CSV,
    IMPORT_RESERVATIONS_CSV_FAILURE,
    IMPORT_RESERVATIONS_CSV_SUCCESS,
    RESET_IMPORT_RESERVATIONS_STATE,
    CREATE_ACCESS_API_START_OVER,
    STORE_GUARD_RESERVATION_ID,
    END_PANEL_CALL_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    addingReservation: false,
    addingReservationSuccess: false,
    addingReservationError: null,
    cancellingReservation: false,
    cancellingReservationSuccess: false,
    cancellingReservationError: null,
    reinstatingReservation: false,
    reinstatingReservationSuccess: false,
    reinstatingReservationError: null,
    updatingReservation: false,
    updatingReservationSuccess: false,
    updatingReservationError: null,
    reservations: [],
    loadingReservations: false,
    loadingReservationsError: null,
    validatingReservation: false,
    validationSuccess: null,
    validationError: null,
    addingTenant: false,
    addingTenantSuccess: false,
    addingTenantError: null,
    importing: false,
    importedReservations: false,
    failedImportReservations: null,
    guardReservationId: null
};

const reservationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_RESERVATION:
            return {
                ...state,
                addingReservation: true,
                addingReservationError: null,
                addingReservationSuccess: false
            };
        case ADD_RESERVATION_SUCCESS:
            return {
                ...state,
                addingReservation: false,
                addingReservationSuccess: true
            };
        case ADD_RESERVATION_FAILURE:
            return {
                ...state,
                addingReservation: false,
                addingReservationSuccess: false,
                addingReservationError: action.payload
            };
        case RESET_ADD_RESERVATION_STATE:
            return {
                ...state,
                addingReservation: false,
                addingReservationSuccess: false,
                addingReservationError: null
            };
        case CANCEL_RESERVATION:
            return {
                ...state,
                cancellingReservation: true,
                cancellingReservationError: null,
                cancellingReservationSuccess: false
            };
        case CANCEL_RESERVATION_SUCCESS:
            return {
                ...state,
                cancellingReservation: false,
                cancellingReservationSuccess: true
            };
        case CANCEL_RESERVATION_FAILURE:
            return {
                ...state,
                cancellingReservation: false,
                cancellingReservationSuccess: false,
                cancellingReservationError: action.payload
            };
        case REINSTATE_RESERVATION:
            return {
                ...state,
                reinstatingReservation: true,
                reinstatingReservationError: null,
                reinstatingReservationSuccess: false
            };
        case REINSTATE_RESERVATION_SUCCESS:
            return {
                ...state,
                reinstatingReservation: false,
                reinstatingReservationSuccess: true
            };
        case REINSTATE_RESERVATION_FAILURE:
            return {
                ...state,
                reinstatingReservation: false,
                reinstatingReservationSuccess: false,
                reinstatingReservationError: action.payload
            };
        case UPDATE_RESERVATION:
            return {
                ...state,
                updatingReservation: true,
                updatingReservationError: null,
                updatingReservationSuccess: false
            };
        case UPDATE_RESERVATION_SUCCESS:
            return {
                ...state,
                updatingReservation: false,
                updatingReservationSuccess: true
            };
        case UPDATE_RESERVATION_FAILURE:
            return {
                ...state,
                updatingReservation: false,
                updatingReservationSuccess: false,
                updatingReservationError: action.payload
            };
        case VALIDATE_RESERVATION:
            return {
                ...state,
                validatingReservation: true,
                validationError: null
            };
        case VALIDATE_RESERVATION_SUCCESS:
            return {
                ...state,
                validatingReservation: false,
                validationSuccess: action.payload
            };
        case VALIDATE_RESERVATION_FAILURE:
            return {
                ...state,
                validatingReservation: false,
                validationError: action.payload
            };
        case GET_RESERVATIONS:
            return {
                ...state,
                loadingReservations: true,
                loadingReservationsError: null
            };
        case GET_RESERVATIONS_SUCCESS:
            return {
                ...state,
                loadingReservations: false,
                reservations: action.payload
            };
        case GET_RESERVATIONS_FAILURE:
            return {
                ...state,
                loadingReservations: false,
                loadingReservationsError: action.payload
            };
        case CREATE_TENANT_ACCESS:
            return {
                ...state,
                addingTenant: true,
                addingTenantError: null,
                validationSuccess: null
            };
        case CREATE_TENANT_ACCESS_SUCCESS:
            return {
                ...state,
                addingTenant: false,
                addingTenantSuccess: action.payload
            };
        case CREATE_TENANT_ACCESS_FAILURE:
            return {
                ...state,
                addingTenant: false,
                addingTenantError: action.payload
            };
        case RESET_RESERVATION_ACTIONS_STATE:
            return {
                ...state,
                cancellingReservation: false,
                cancellingReservationSuccess: false,
                cancellingReservationError: null,
                reinstatingReservation: false,
                reinstatingReservationSuccess: false,
                reinstatingReservationError: null,
                updatingReservation: false,
                updatingReservationSuccess: false,
                updatingReservationError: null
            };
        case IMPORT_RESERVATIONS_CSV:
            return {
                ...state,
                importing: true,
                importedReservations: false,
                failedImportReservations: null
            };
        case IMPORT_RESERVATIONS_CSV_SUCCESS:
            return {
                ...state,
                importing: false,
                importedReservations: action.payload,
                failedImportReservations: null
            };
        case IMPORT_RESERVATIONS_CSV_FAILURE:
            return {
                ...state,
                importing: false,
                importedReservations: false,
                failedImportReservations: action.payload
            };
        case RESET_IMPORT_RESERVATIONS_STATE:
            return {
                ...state,
                importing: false,
                importedReservations: false,
                failedImportReservations: null
            };
        case CREATE_ACCESS_API_START_OVER:
            return {
                ...state,
                addingTenantSuccess: INITIAL_STATE.addingTenantSuccess
            };
        case STORE_GUARD_RESERVATION_ID:
            return {
                ...state,
                guardReservationId: action.payload
            };
        case END_PANEL_CALL_SUCCESS:
            return {
                ...state,
                guardReservationId: INITIAL_STATE.guardReservationId
            };
        default:
            return state;
    }
};

export default reservationReducer;
