// React
import React, { useEffect } from 'react';

// Assets
import { DeleteFilled } from '@ant-design/icons';

// Components
import IconButton from 'components/@extended/IconButton';

// Packages
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

// Styles
import 'react-widgets/scss/styles.scss';

// Utils
import { countries, errorMessage, nameRegExp, roleTypes } from 'utils/constants';

const CreateUpdateMemberModal = ({
    onClose,
    selectedMember,
    member,
    found,
    success,
    property,
    loading,
    error,
    type,
    orgId,
    checkingExistingMember,
    addingNewMember,
    resetNewMember,
    updatingMember,
    handlePropertyMembersDelete,
    occupants
}) => {
    const submit = values => {
        const phone = values.phone
            ? {
                  number: Number.parseInt(
                      values.phone.replace(/[^0-9]/g, '').length > 10
                          ? values.phone
                                .replace(/[^0-9]/g, '')
                                .substring(
                                    values.phone.replace(/[^0-9]/g, '').length - 10
                                )
                          : values.phone.replace(/[^0-9]/g, '')
                  ),
                  code: Number.parseInt(values.code),
                  country: values.country
              }
            : null;
        const phone_number = values.phone
            ? values.phone.replace(/[^0-9]/g, '').length > 10
                ? `${values.code}${values.phone
                      .replace(/[^0-9]/g, '')
                      .substring(values.phone.replace(/[^0-9]/g, '').length - 10)}`
                : `${values.code}${values.phone.replace(/[^0-9]/g, '')}`
            : null;
        const email =
            values.email.trim() === '' ? null : values.email.toLowerCase().trim();

        if (type === 'edit') {
            updatingMember({
                member: {
                    directory: !values.calls,
                    email,
                    emailChange: email !== selectedMember.email,
                    first_name: values.first_name.toLowerCase().trim(),
                    last_name: values.last_name.toLowerCase().trim(),
                    phone: phone,
                    phone_number,
                    suspended: values.suspended,
                    uid: selectedMember.uid,
                    permissions: {
                        invite: !values.invite,
                        calls: !values.calls
                    },
                    type: values.type
                },
                orgId,
                propertyId: property.id
            });
            onClose();
        } else {
            if (!found) {
                checkingExistingMember({
                    member: {
                        ...values,
                        first_name: values.first_name.toLowerCase().trim(),
                        last_name: values.last_name.toLowerCase().trim(),
                        address_1: property.address_1,
                        address: property,
                        email,
                        phone,
                        phone_number,
                        directory: !values.calls,
                        permissions: {
                            invite: !values.invite,
                            calls: !values.calls
                        },
                        type: values.type
                    }
                });
            } else {
                addingNewMember({
                    member: {
                        ...values,
                        first_name: values.first_name.toLowerCase().trim(),
                        last_name: values.last_name.toLowerCase().trim(),
                        address_1: property.address_1,
                        address: property,
                        email,
                        phone,
                        phone_number,
                        directory: !values.calls,
                        permissions: {
                            invite: !values.invite,
                            calls: !values.calls
                        },
                        type: values.type
                    },
                    exist: found
                });
            }
        }
    };

    const initialValues =
        type === 'edit'
            ? {
                  first_name: selectedMember?.first_name?.trim() || '',
                  last_name: selectedMember?.last_name?.trim() || '',
                  email: selectedMember?.email?.trim() || '',
                  phone: selectedMember?.phone?.number
                      ? `${selectedMember.phone.number}`
                      : '',
                  code: selectedMember?.phone?.code
                      ? `${selectedMember.phone.code}`
                      : '1',
                  country: selectedMember?.phone?.country
                      ? `${selectedMember.phone.country}`
                      : 'US',
                  suspended: selectedMember?.properties[property.id]?.suspended,
                  calls: !selectedMember?.properties[property.id]?.permissions?.calls,
                  invite: !selectedMember?.properties[property.id]?.permissions?.invite,
                  type: selectedMember?.properties[property.id]?.type
              }
            : {
                  first_name: selectedMember?.first_name?.trim() || '',
                  last_name: selectedMember?.last_name?.trim() || '',
                  email: selectedMember?.email?.trim() || '',
                  phone: selectedMember?.phone?.number
                      ? `${selectedMember.phone.number}`
                      : '',
                  code: selectedMember?.phone?.code
                      ? `${selectedMember.phone.code}`
                      : '1',
                  country: selectedMember?.phone?.country
                      ? `${selectedMember.phone.country}`
                      : 'US',
                  calls: false,
                  invite: false,
                  type: 'member'
              };

    const validationSchema = Yup.object().shape(
        {
            first_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.firstName.valid)
                .required(errorMessage.firstName.required),
            last_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.lastName.valid)
                .required(errorMessage.lastName.required),
            email:
                type !== 'edit'
                    ? Yup.string()
                          .trim()
                          .lowercase()
                          .email(errorMessage.email.valid)
                          .when('phone', {
                              is: phone => !phone || phone.length === 0,
                              then: () =>
                                  Yup.string()
                                      .email(errorMessage.email.valid)
                                      .required(errorMessage.emailOrPhone.required),
                              otherwise: () =>
                                  Yup.string().email(errorMessage.email.valid)
                          })
                    : Yup.string()
                          .trim()
                          .lowercase()
                          .email(errorMessage.email.valid)
                          .required(errorMessage.email.required),
            phone:
                type !== 'edit'
                    ? Yup.string()
                          .trim()
                          .when('email', {
                              is: email => !email || email.length === 0,
                              then: () =>
                                  Yup.string().required(
                                      errorMessage.phoneOrEmail.required
                                  ),
                              otherwise: () => Yup.string().trim()
                          })
                    : Yup.string().trim().required(errorMessage.phone.required),
            country: Yup.string().trim(),
            code: Yup.string().trim(),
            suspended: Yup.boolean(),
            calls: Yup.boolean(),
            invite: Yup.boolean(),
            type: Yup.string()
        },
        [
            ['phone', 'email'],
            ['email', 'phone']
        ]
    );

    const validatePhoneEmail = ({ phone, code, country, email }) => {
        const checkExistingOccupantEmail = email => {
            const occupantEmails = occupants.map(occupant =>
                occupant.email.trim().toLowerCase()
            );
            if (
                email &&
                email.trim() !== '' &&
                occupantEmails.indexOf(email.trim().toLowerCase()) > -1 &&
                type !== 'edit'
            ) {
                return false;
            } else {
                return true;
            }
        };
        const checkExistingOccupantPhone = phone => {
            const occupantPhones = occupants.map(occupant =>
                occupant.phone.number.toString().trim()
            );
            const formattedPhone = phone.replace(/[^0-9]/g, '');
            if (
                formattedPhone &&
                formattedPhone.trim() !== '' &&
                occupantPhones.indexOf(formattedPhone.trim()) > -1 &&
                type !== 'edit'
            ) {
                return false;
            } else {
                return true;
            }
        };
        const phoneSchema = Yup.string().phone(country);

        const isPhoneValid = phone ? phoneSchema.isValidSync(code + phone) : true;
        const phoneIsUnique = phone ? checkExistingOccupantPhone(phone) : true;
        const emailIsUnique = email ? checkExistingOccupantEmail(email) : true;

        if (!emailIsUnique) {
            return {
                email: emailIsUnique ? undefined : `Occupant and or email already exists`
            };
        } else if (!isPhoneValid) {
            return {
                phone: isPhoneValid
                    ? undefined
                    : 'Mobile phone needs to be a valid number'
            };
        } else if (!phoneIsUnique) {
            return {
                phone: phoneIsUnique ? undefined : 'Occupant and or phone already exists'
            };
        }
    };

    useEffect(() => {
        if (success) {
            onClose();
        }
    }, [success, onClose]);

    return (
        <>
            <DialogTitle>
                {type === 'edit'
                    ? 'Edit Occupant'
                    : found
                    ? 'Found Existing Sage Member!'
                    : 'Add New Occupant'}
            </DialogTitle>
            <Divider />
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validate={validatePhoneEmail}
                validationSchema={validationSchema}
                validateOnChange
                enableReinitialize
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    setFieldValue,
                    handleBlur,
                    errors,
                    values
                }) => (
                    <>
                        <DialogContent sx={{ p: 2.5, minHeight: '70vh' }}>
                            {!found ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>First Name</InputLabel>
                                            <TextField
                                                required
                                                id="firstNameBasic"
                                                name="first_name"
                                                placeholder="First Name"
                                                fullWidth
                                                autoComplete="given-name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={
                                                    touched.first_name &&
                                                    !!errors.first_name
                                                }
                                                helperText={
                                                    touched.first_name &&
                                                    errors.first_name
                                                        ? errors.first_name
                                                        : ''
                                                }
                                                value={values.first_name}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Last Name</InputLabel>
                                            <TextField
                                                required
                                                id="lastNameBasic"
                                                name="last_name"
                                                placeholder="Last name"
                                                fullWidth
                                                autoComplete="family-name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={
                                                    touched.last_name &&
                                                    !!errors.last_name
                                                }
                                                helperText={
                                                    touched.last_name && errors.last_name
                                                        ? errors.last_name
                                                        : ''
                                                }
                                                value={values.last_name}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Email</InputLabel>
                                            <TextField
                                                required
                                                id="emailBasic"
                                                name="email"
                                                placeholder="Email"
                                                fullWidth
                                                autoComplete="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={touched.email && !!errors.email}
                                                helperText={
                                                    touched.email && errors.email
                                                        ? errors.email
                                                        : ''
                                                }
                                                value={values.email}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2} sm={2}>
                                        <Stack spacing={0.5}>
                                            <InputLabel htmlFor="country">
                                                Country Code
                                            </InputLabel>
                                            <Select
                                                id="countryCodeSelect"
                                                name="country"
                                                onChange={e => {
                                                    const selectedCountry =
                                                        countries.find(
                                                            country =>
                                                                country.value ===
                                                                e.target.value
                                                        );
                                                    if (selectedCountry) {
                                                        setFieldValue(
                                                            'code',
                                                            selectedCountry.phoneCode
                                                        );
                                                        setFieldValue(
                                                            'country',
                                                            selectedCountry.value
                                                        );
                                                    }
                                                }}
                                                value={values.country}
                                                label="Country Code"
                                            >
                                                {countries.map(country => (
                                                    <MenuItem
                                                        key={country.value}
                                                        value={country.value}
                                                    >
                                                        {country.flagIcon} +
                                                        {country.phoneCode}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={10} sm={6}>
                                        <Stack spacing={0.5}>
                                            <InputLabel>Phone</InputLabel>
                                            <TextField
                                                required
                                                id="phoneBasic"
                                                name="phone"
                                                placeholder="Phone"
                                                fullWidth
                                                autoComplete="phone"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={touched.phone && !!errors.phone}
                                                helperText={
                                                    touched.phone && errors.phone
                                                        ? errors.phone
                                                        : ''
                                                }
                                                value={values.phone}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            sx={{ marginBottom: '20px' }}
                                        >
                                            <Stack spacing={0.5}>
                                                <InputLabel htmlFor="type">
                                                    Type
                                                </InputLabel>
                                                <Select
                                                    required
                                                    id="typeSelect"
                                                    name="type"
                                                    sx={{
                                                        textTransform: 'capitalize'
                                                    }}
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'type',
                                                            e.target.value
                                                        );
                                                        if (e.target.value === 'minor') {
                                                            setFieldValue('calls', true);
                                                            setFieldValue('invite', true);
                                                        }
                                                    }}
                                                    value={values.type}
                                                    label="Type"
                                                    error={Boolean(
                                                        touched.type && errors.type
                                                    )}
                                                >
                                                    {roleTypes.map(item => (
                                                        <MenuItem
                                                            key={item}
                                                            value={item}
                                                            sx={{
                                                                textTransform:
                                                                    'capitalize'
                                                            }}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.type && errors.type ? (
                                                    <FormHelperText
                                                        sx={{
                                                            color: '#F04134',
                                                            marginLeft: '16px !important'
                                                        }}
                                                    >
                                                        {touched.type && errors.type}
                                                    </FormHelperText>
                                                ) : null}
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    {type === 'edit' && (
                                        <Grid item xs={12} sm={12}>
                                            <Stack spacing={0.5}>
                                                <Box>
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Checkbox
                                                                checked={values.suspended}
                                                                onChange={() =>
                                                                    setFieldValue(
                                                                        'suspended',
                                                                        !values.suspended
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                sx={{
                                                                    color: '#8c8c8c'
                                                                }}
                                                            >
                                                                Suspended
                                                            </Typography>
                                                        }
                                                        labelPlacement="end"
                                                        sx={{ ml: 0 }}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.calls}
                                                            disabled={
                                                                values.type === 'minor'
                                                            }
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'calls',
                                                                    !values.calls
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: '#8c8c8c'
                                                            }}
                                                        >
                                                            Hide from calls
                                                        </Typography>
                                                    }
                                                    labelPlacement="end"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Stack spacing={0.5}>
                                            <Box>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            checked={values.invite}
                                                            disabled={
                                                                values.type === 'minor'
                                                            }
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'invite',
                                                                    !values.invite
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: '#8c8c8c'
                                                            }}
                                                        >
                                                            Disable guest invites
                                                        </Typography>
                                                    }
                                                    labelPlacement="end"
                                                    sx={{ ml: 0 }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontSize: '14px', mb: 1 }}
                                        textTransform={'capitalize'}
                                    >
                                        {`Name:  ${found[0].first_name} ${found[0].last_name}`}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontSize: '14px', mb: 1 }}
                                    >
                                        {`Email:  ${found[0].email}`}
                                    </Typography>
                                    {found[0].phone?.number && (
                                        <Typography
                                            variant="body1"
                                            sx={{ fontSize: '14px', mb: 1 }}
                                        >
                                            {`Phone:  ${found[0].phone?.number}`}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    {type === 'edit' && (
                                        <Tooltip title="Delete Occupant" placement="top">
                                            <IconButton
                                                size="large"
                                                color="error"
                                                onClick={() =>
                                                    handlePropertyMembersDelete({
                                                        type: 'member'
                                                    })
                                                }
                                            >
                                                <DeleteFilled />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Button
                                            color="error"
                                            onClick={() => {
                                                if (found) {
                                                    resetNewMember('found');
                                                } else {
                                                    onClose();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            {type === 'edit' ? 'Update' : 'Add'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default CreateUpdateMemberModal;
