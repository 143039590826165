import {
    ADD_MEMBER,
    ADD_MEMBER_SUCCESS,
    ADD_MEMBER_FAILURE,
    RESET_MEMBER_CREATION_STATE,
    IMPORT_MEMBERS,
    IMPORT_MEMBERS_SUCCESS,
    IMPORT_MEMBERS_FAILURE,
    RESET_MEMBER_IMPORT_STATE,
    GET_IMPORTED_MEMBERS_FOR_ORG_SUCCESS,
    GET_IMPORTED_MEMBERS_FOR_ORG_FAILURE,
    SET_INVITE_PENDING_MEMBERS,
    RESET_INVITE_PENDING_MEMBERS,
    ADD_INVITE_PENDING_MEMBERS,
    ADD_INVITE_PENDING_MEMBERS_SUCCESS,
    ADD_INVITE_PENDING_MEMBERS_FAILURE,
    GET_INVITED_MEMBERS_FOR_ORG_SUCCESS,
    GET_INVITED_MEMBERS_FOR_ORG_FAILURE,
    GET_COMMUNITY_MEMBERS_FOR_ORG_SUCCESS,
    GET_COMMUNITY_MEMBERS_FOR_ORG_FAILURE,
    UPDATE_USER_AVATAR,
    UPDATE_USER_AVATAR_SUCCESS,
    UPDATE_USER_AVATAR_FAILURE,
    UPDATE_USER_PROFILE_INFO,
    UPDATE_USER_PROFILE_INFO_SUCCESS,
    UPDATE_USER_PROFILE_INFO_FAILURE,
    UPDATE_USER_PROFILE_INFO_RESET,
    ADD_MEMBERS_FROM_LIST,
    DOWNLOAD_CSV_TEMPLATE,
    DOWNLOAD_CSV_TEMPLATE_SUCCESS,
    DOWNLOAD_CSV_TEMPLATE_FAILURE
} from './types';

export const addNewMember = memberInfo => {
    return {
        type: ADD_MEMBER,
        payload: memberInfo
    };
};

export const addNewMemberSuccess = memberEmail => {
    return {
        type: ADD_MEMBER_SUCCESS,
        payload: memberEmail
    };
};

export const addNewMemberFailure = error => {
    return {
        type: ADD_MEMBER_FAILURE,
        payload: error
    };
};

export const resetMemberCreationState = () => {
    return {
        type: RESET_MEMBER_CREATION_STATE
    };
};

export const importMembers = data => {
    return {
        type: IMPORT_MEMBERS,
        payload: data
    };
};

export const importMembersSuccess = result => {
    return {
        type: IMPORT_MEMBERS_SUCCESS,
        payload: result
    };
};

export const importMembersFailure = error => {
    return {
        type: IMPORT_MEMBERS_FAILURE,
        payload: error
    };
};

export const resetMemberImportState = () => {
    return {
        type: RESET_MEMBER_IMPORT_STATE
    };
};

export const getImportedMembersSuccess = importedMembers => {
    return {
        type: GET_IMPORTED_MEMBERS_FOR_ORG_SUCCESS,
        payload: importedMembers
    };
};

export const getImportedMembersFailure = error => {
    return {
        type: GET_IMPORTED_MEMBERS_FOR_ORG_FAILURE,
        payload: error
    };
};

export const setInvitePendingMembers = members => {
    return {
        type: SET_INVITE_PENDING_MEMBERS,
        payload: members
    };
};

export const resetInvitePendingMembers = () => {
    return {
        type: RESET_INVITE_PENDING_MEMBERS
    };
};

export const addInvitePendingMembers = data => {
    return {
        type: ADD_INVITE_PENDING_MEMBERS,
        payload: data
    };
};

export const addInvitePendingMembersSuccess = () => {
    return {
        type: ADD_INVITE_PENDING_MEMBERS_SUCCESS
    };
};

export const addInvitePendingMembersFailure = error => {
    return {
        type: ADD_INVITE_PENDING_MEMBERS_FAILURE,
        payload: error
    };
};

export const getInvitedMembersSuccess = importedMembers => {
    return {
        type: GET_INVITED_MEMBERS_FOR_ORG_SUCCESS,
        payload: importedMembers
    };
};

export const getInvitedMembersFailure = error => {
    return {
        type: GET_INVITED_MEMBERS_FOR_ORG_FAILURE,
        payload: error
    };
};

export const getCommunityMembersSuccess = importedMembers => {
    return {
        type: GET_COMMUNITY_MEMBERS_FOR_ORG_SUCCESS,
        payload: importedMembers
    };
};

export const getCommunityMembersFailure = error => {
    return {
        type: GET_COMMUNITY_MEMBERS_FOR_ORG_FAILURE,
        payload: error
    };
};

export const updateUserAvatar = data => {
    return {
        type: UPDATE_USER_AVATAR,
        payload: data
    };
};

export const updateUserAvatarSuccess = downloadUrl => {
    return {
        type: UPDATE_USER_AVATAR_SUCCESS,
        payload: downloadUrl
    };
};

export const updateUserAvatarFailure = error => {
    return {
        type: UPDATE_USER_AVATAR_FAILURE,
        payload: error
    };
};

export const updateUserProfileInfo = data => {
    return {
        type: UPDATE_USER_PROFILE_INFO,
        payload: data
    };
};

export const updateUserProfileInfoSuccess = () => {
    return {
        type: UPDATE_USER_PROFILE_INFO_SUCCESS
    };
};

export const updateUserProfileInfoFailure = error => {
    return {
        type: UPDATE_USER_PROFILE_INFO_FAILURE,
        payload: error
    };
};

export const updateUserProfileInfoReset = () => {
    return {
        type: UPDATE_USER_PROFILE_INFO_RESET
    };
};

export const addingMembersFromList = () => {
    return {
        type: ADD_MEMBERS_FROM_LIST
    };
};

export const downloadCsvTemplate = collection => {
    return {
        type: DOWNLOAD_CSV_TEMPLATE,
        payload: collection
    };
};

export const downloadCsvTemplateSuccess = url => {
    return {
        type: DOWNLOAD_CSV_TEMPLATE_SUCCESS,
        payload: url
    };
};

export const downloadCsvTemplateFailure = error => {
    return {
        type: DOWNLOAD_CSV_TEMPLATE_FAILURE,
        payload: error
    };
};
