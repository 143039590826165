import {
    GET_LPR_COLLECTION,
    GET_LPR_COLLECTION_SUCCESS,
    GET_LPR_COLLECTION_FAILURE,
    SET_LPR_QUERY,
    SET_ACTIVE_USER_ORG,
    STORE_LPR_IDS
} from '../actions/types';
import { tsFromJsDate } from 'utils/Helpers';

const INITIAL_STATE = {
    lprDocs: [],
    lprIds: [],
    fromDate: tsFromJsDate(new Date(Date.now() - 60 * 60 * 1000)),
    toDate: tsFromJsDate(new Date(Date.now())),
    queryLpr: false,
    loading: true,
    error: null
};

const lprReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LPR_COLLECTION:
            return {
                ...state,
                queryLpr: false,
                loading: true,
                error: null
            };
        case GET_LPR_COLLECTION_SUCCESS:
            return {
                ...state,
                lprDocs: action.payload.docs,
                lprIds: action.payload.ids,
                loading: false,
                error: null
            };
        case GET_LPR_COLLECTION_FAILURE:
            return {
                ...state,
                lprDocs: action.payload.docs,
                lprIds: action.payload.ids,
                loading: false,
                error: action.payload
            };
        case SET_LPR_QUERY:
            return {
                ...state,
                lprDocs: [],
                fromDate: action.payload.from,
                toDate: action.payload.to,
                queryLpr: true,
                loading: true,
                error: null
            };
        case SET_ACTIVE_USER_ORG:
            return {
                ...state,
                fromDate: tsFromJsDate(new Date(Date.now() - 60 * 60 * 1000)),
                toDate: tsFromJsDate(new Date(Date.now())),
                lprDocs: [],
                loading: true,
                error: null
            };
        case STORE_LPR_IDS:
            return {
                lprIds: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default lprReducer;
