import React from 'react';
import { Box } from '@mui/material';

const MessageDisplay = ({ children, sx }) => {
    return (
        <Box
            sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '4px',
                padding: '50px',
                color: 'text.secondary',
                ...sx
            }}
        >
            {children}
        </Box>
    );
};

export default MessageDisplay;
