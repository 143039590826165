import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import userSagas from './User';
import orgSagas from './Org';
import invitesSagas from './Invites';
import propertiesSaga from './Properties';
import guestsSaga from './Guests';
import panelSagas from './Panels';
import intercomSagas from './Intercom';
import callsSagas from './Calls';
import virtualGuardSagas from './VirtualGuard';
import registrationSaga from './Registration';
import membersSagas from './Members';
import otcSagas from './Otc';
import liveKitSagas from './LiveKit';
import reservationsSaga from './Reservations';
import accessKeysSaga from './AccessKeys';
import lprSagas from './Lpr';
import deviceOperationsSagas from './DeviceOperations';
import managersSaga from './Managers';

export default function* rootSaga() {
    yield all([
        authSagas(),
        accessKeysSaga(),
        userSagas(),
        orgSagas(),
        invitesSagas(),
        propertiesSaga(),
        guestsSaga(),
        panelSagas(),
        intercomSagas(),
        callsSagas(),
        virtualGuardSagas(),
        registrationSaga(),
        membersSagas(),
        otcSagas(),
        reservationsSaga(),
        liveKitSagas(),
        lprSagas(),
        deviceOperationsSagas(),
        managersSaga()
    ]);
}
