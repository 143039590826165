import {
    SET_ACTIVE_USER_ORG,
    SET_ACTIVE_USER_ORG_SUCCESS,
    SET_ACTIVE_USER_ORG_FAILURE,
    SET_USER_COLLECTION,
    SET_ACTIVE_USER_ORG_PERMISSIONS,
    SET_ACTIVE_USER_PROPERTY,
    SET_ACTIVE_USER_PROPERTY_SUCCESS,
    SET_ACTIVE_USER_PROPERTY_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    userData: null,
    activePermissions: null,
    activeOrg: null,
    changingFocus: false,
    focusError: null,
    success: null,
    activeProperty: null,
    propertyLoading: false,
    propertyError: null
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_COLLECTION:
            return {
                ...state,
                userData: action.payload
            };
        case SET_ACTIVE_USER_ORG:
            return {
                ...state,
                changingFocus: true,
                focusError: null
            };
        case SET_ACTIVE_USER_ORG_SUCCESS:
            return {
                ...state,
                changingFocus: false,
                activeOrg: action.payload.activeOrg
            };
        case SET_ACTIVE_USER_ORG_FAILURE:
            return {
                ...state,
                changingFocus: false,
                focusError: action.payload
            };
        case SET_ACTIVE_USER_ORG_PERMISSIONS:
            return {
                ...state,
                activePermissions: action.payload.activePermissions
            };
        case SET_ACTIVE_USER_PROPERTY:
            return {
                ...state,
                propertyLoading: true,
                propertyError: null
            };
        case SET_ACTIVE_USER_PROPERTY_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    active_property_id: action.payload.id
                },
                activeProperty: action.payload,
                propertyLoading: false
            };
        case SET_ACTIVE_USER_PROPERTY_FAILURE:
            return {
                ...state,
                propertyError: action.payload,
                propertyLoading: false
            };
        default:
            return state;
    }
};

export default userReducer;
