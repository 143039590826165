import { all, fork, takeLatest, take, cancelled, race, put } from 'redux-saga/effects';

import { GET_USER_COLLECTION, LOGOUT_USER } from '../actions/types';

import { eventChannel } from 'redux-saga';

import { db, fsTools } from 'config/firebase';

import { settingActivePermissions, storeUserCollection } from '../actions/User';
import { activeOrgCollectionWatch, orgCollectionWatch } from './Org';
import { listenToAccessKeysAndGuestInvites } from './Guests';
import { getPanelDevices, getRtdbOrgDevices } from './Panels';
import { callsCollectionWatch } from './Calls';
import { deviceOperationsWatch } from './DeviceOperations';

import { log } from 'utils/Loggers';
import { intercomCollectionWatch } from './Intercom';
import { reservationsCollectionWatch } from './Reservations';
import {propertyCollectionWatch} from './Properties';

// import { checkExpiration } from '../../utils/Helpers';

// const refreshTwilioToken = func.httpsCallable('refreshTwilioAccessToken');

////////////// Get User Profile ////////////////

export function* userCollectionWatch(user) {
    let unsubcscribeUserCollectionData;
    const userCollectionChannel = eventChannel(emit => {
        const usersRef = fsTools.doc(db, 'users', user.uid);

        unsubcscribeUserCollectionData = fsTools.onSnapshot(usersRef, doc => {
            if (doc) {
                emit(doc.data());
            } else {
                doc = { exists: false };
                emit({ doc });
            }
        });
        return unsubcscribeUserCollectionData;
    });
    try {
        while (true) {
            const { userSignOut, userCollectionData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userCollectionData: take(userCollectionChannel)
            });

            if (userSignOut) {
                userCollectionChannel.close(); // Detach saga event emitter
            } else {
                // WIP we need to tidy this up
                yield put(storeUserCollection(userCollectionData));
                if (userCollectionData.role === 'resi') {
                    if (userCollectionData.active_property_id) {
                        yield fork(propertyCollectionWatch, { propertyId: userCollectionData.active_property_id });
                    }

                    yield fork(listenToAccessKeysAndGuestInvites);

                    return;
                }

                if (
                    userCollectionData.role === 'operator' &&
                    userCollectionData.type !== 'super_admin'
                ) {
                    yield fork(getRtdbOrgDevices, userCollectionData);
                    yield put(
                        settingActivePermissions({
                            activePermissions:
                                userCollectionData.orgs[userCollectionData.active_org_id]
                                    ?.permissions
                        })
                    );
                }
                // if (userCollectionData?.twilio) {
                //     if (checkExpiration(userCollectionData.twilio.updated_at.seconds)) {
                //         refreshTwilioToken({ userId: userCollectionData.uid });
                //     }
                // }
                // // yield fork(userCollectionData);
                // // if (userCollectionData.type === 'super_admin') {
                yield fork(orgCollectionWatch, userCollectionData); // org list
                // here I am confused between primary org and active org
                yield fork(activeOrgCollectionWatch, userCollectionData.active_org_id); // org community data
                // yield fork(
                //     activeOrgCollectionWatch,
                //     userCollectionData.type === userTypes.SUPER_ADMIN
                //         ? userCollectionData.primary_org
                //         : userCollectionData.active_org_id
                // ); // org community data

                if (
                    (userCollectionData?.active_station_id ||
                        userCollectionData.type === 'virtual_guard') &&
                    userCollectionData.type !== 'super_admin'
                ) {
                    yield fork(callsCollectionWatch, userCollectionData);
                    yield fork(intercomCollectionWatch, userCollectionData);
                }
                yield fork(reservationsCollectionWatch, {
                    payload: userCollectionData.active_org_id
                        ? userCollectionData.active_org_id
                        : userCollectionData.primary_org
                });
                if (userCollectionData.type === 'super_admin') {
                    yield fork(deviceOperationsWatch);
                    yield fork(getPanelDevices);
                }
                // yield fork(reservationsCollectionWatch, {
                //     payload: userCollectionData.active_org_id
                //         ? userCollectionData.active_org_id
                //         : userCollectionData.primary_org
                // });
                // yield fork(panelCollectionWatch, userCollectionData); // admin panel data aggregate doc
                // }
            }
        }
    } catch (error) {
        log('User Error: getting user collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubcscribeUserCollectionData(); // Detach firebase listener
        if (yield cancelled()) {
            userCollectionChannel.close(); // Detach saga event emitter
            unsubcscribeUserCollectionData(); // Detach firebase listener
        }
    }
}

///////////// Action Creators For Root Saga //////////////////

export function* getUserCollection() {
    yield takeLatest(GET_USER_COLLECTION, userCollectionWatch);
}

export default function* rootSaga() {
    yield all([fork(getUserCollection)]);
}
