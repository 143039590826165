import {
    ADD_NEW_REGISTRATION,
    ADD_NEW_REGISTRATION_SUCCESS,
    ADD_NEW_REGISTRATION_FAILURE,
    RESET_ADD_NEW_REGISTRATION,
    ADD_NEW_OCCUPANT_REGISTRATION,
    REMOVE_REGISTRATION,
    REMOVE_REGISTRATION_SUCCESS,
    REMOVE_REGISTRATION_FAILURE,
    RESET_REMOVE_REGISTRATION,
    GET_REGISTRATIONS_BY_PROPERTY_ID,
    GET_REGISTRATIONS_BY_PROPERTY_ID_SUCCESS,
    GET_REGISTRATIONS_BY_PROPERTY_ID_FAILURE,
    EDIT_REGISTRATION,
    EDIT_REGISTRATION_SUCCESS,
    EDIT_REGISTRATION_FAILURE,
    RESET_EDIT_REGISTRATION,
    UPDATE_FAMILY_MEMBER,
    UPDATE_FAMILY_MEMBER_SUCCESS,
    UPDATE_FAMILY_MEMBER_FAILURE,
    RESET_UPDATE_FAMILY_MEMBER
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    error: null,
    createError: null,
    member: null,
    success: null,
    removeRegistrationSuccess: null,
    propertyRegistrations: null
};

const registrationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_NEW_OCCUPANT_REGISTRATION:
            return {
                ...state,
                member: action.payload.member,
                loading: true,
                createError: null
            };
        case ADD_NEW_REGISTRATION:
            return {
                ...state,
                member: action.payload.member,
                loading: true,
                createError: null
            };
        case ADD_NEW_REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: null,
                createError: null,
                success: true
            };
        case ADD_NEW_REGISTRATION_FAILURE:
            return {
                ...state,
                loading: null,
                createError: action.payload.error
            };
        case RESET_ADD_NEW_REGISTRATION:
            return {
                ...state,
                loading: null,
                error: null,
                success: null,
                createError: null
            };
        case EDIT_REGISTRATION:
            return {
                ...state,
                member: action.payload.member,
                loading: true,
                createError: null
            };
        case EDIT_REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: null,
                createError: null,
                success: true
            };
        case EDIT_REGISTRATION_FAILURE:
            return {
                ...state,
                loading: null,
                createError: action.payload.error
            };
        case RESET_EDIT_REGISTRATION:
            return {
                ...state,
                loading: null,
                error: null,
                success: null,
                createError: null
            };
        case UPDATE_FAMILY_MEMBER:
            return {
                ...state,
                success: null,
                loading: true,
                createError: null
            };
        case UPDATE_FAMILY_MEMBER_SUCCESS:
            return {
                ...state,
                loading: null,
                createError: null,
                success: true
            };
        case UPDATE_FAMILY_MEMBER_FAILURE:
            return {
                ...state,
                loading: null,
                success: null,
                createError: action.payload.error
            };
        case RESET_UPDATE_FAMILY_MEMBER:
            return {
                ...state,
                loading: null,
                createError: null,
                success: null,
                error: null
            };
        case RESET_REMOVE_REGISTRATION:
            return {
                ...state,
                loading: null,
                error: null,
                removeRegistrationSuccess: null
            };
        case REMOVE_REGISTRATION:
            return {
                ...state,
                loading: true
            };
        case REMOVE_REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                removeRegistrationSuccess: true
            };
        case REMOVE_REGISTRATION_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload
            };
        case GET_REGISTRATIONS_BY_PROPERTY_ID:
            return {
                ...state,
                loading: true,
                error: null,
                propertyRegistrations: null
            };
        case GET_REGISTRATIONS_BY_PROPERTY_ID_SUCCESS:
            return {
                ...state,
                loading: null,
                error: null,
                propertyRegistrations: action.payload
            };
        case GET_REGISTRATIONS_BY_PROPERTY_ID_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                propertyRegistrations: null
            };
        default:
            return state;
    }
};

export default registrationReducer;
