import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MY_FAMILY_ADD, MY_FAMILY_EDIT } from 'utils/route-constants';
import { Grid, Typography, Button, Box, List, ListItem } from '@mui/material';
import MessageDisplay from '../common/MessageDisplay';

const FamilyList = ({ members, registrations, userData, activeProperty }) => {
    const navigate = useNavigate();

    registrations = registrations || [];
    members = members || [];

    const membersAndRegistrations = [...registrations, ...members];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        mb: 6,
                        display: 'flex',
                        justifyContent: 'space-between',
                        pt: 2.5,
                        px: 2.5
                    }}
                >
                    <Box>
                        <Typography
                            variant="h3"
                            mb={0}
                            color="text.primary"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            {userData?.first_name || ''} {userData?.last_name || ''}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            className="small"
                            textTransform="capitalize"
                        >
                            {activeProperty?.address?.full_address || ''}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            width: { xs: '100%', sm: 'auto' },
                            mt: { xs: 2, sm: 0 }
                        }}
                    >
                        {activeProperty?.type === 'member' &&
                            activeProperty?.primary &&
                            activeProperty?.permissions?.invite && (
                                <Button
                                    component={Link}
                                    to={MY_FAMILY_ADD}
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                >
                                    Add Family Member
                                </Button>
                            )}
                    </Box>
                </Box>
                <Box sx={{ height: '45vh', overflow: 'auto' }}>
                    <List sx={{ padding: 0, margin: 0, overflow: 'auto' }}>
                        {membersAndRegistrations.length > 0 ? (
                            membersAndRegistrations.map((member, index) => (
                                <ListItem
                                    key={member?.uid || member?.id}
                                    onClick={() =>
                                        navigate(
                                            `${MY_FAMILY_EDIT}/${
                                                member?.uid || member?.id
                                            }`
                                        )
                                    }
                                    sx={{
                                        cursor: 'pointer',
                                        py: 1,
                                        pl: 2.5,
                                        borderBottom:
                                            index === members.length - 1
                                                ? 'none'
                                                : '1px solid #eee',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start'
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        mb={0}
                                        color="text.primary"
                                        textTransform="capitalize"
                                    >
                                        {member.first_name} {member.last_name}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        textTransform="capitalize"
                                    >
                                        {member?.isRegistration
                                            ? 'Pending'
                                            : member?.properties[activeProperty?.id]
                                                  ?.type}
                                    </Typography>
                                </ListItem>
                            ))
                        ) : (
                            <MessageDisplay>
                                No family members or pending registrations found for this
                                property.
                                {activeProperty?.permissions?.invite && (
                                    <>
                                        {' '}
                                        Click{' '}
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontFamily: 'monospace',
                                                backgroundColor: '#f5f5f5',
                                                padding: '2px 4px',
                                                borderRadius: '4px',
                                                display: 'inline-block'
                                            }}
                                        >
                                            Add Family Member
                                        </Typography>{' '}
                                        to add someone to the list.
                                    </>
                                )}
                            </MessageDisplay>
                        )}
                    </List>
                </Box>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData, activeProperty } = user;
    return {
        userData,
        activeProperty
    };
};

export default connect(mapStateToProps, null)(FamilyList);
