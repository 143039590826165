import {
    ADD_NEW_GUEST,
    GET_GUESTS,
    LOGOUT_USER,
    REMOVE_GUEST,
    REMOVE_GUEST_FAILURE,
    REMOVE_GUEST_SUCCESS,
    SET_ACTIVE_USER_PROPERTY_SUCCESS
} from 'store/actions/types';

import {
    all,
    fork,
    takeLatest,
    put,
    call,
    select,
    take,
    race,
    cancelled
} from 'redux-saga/effects';

import { db } from 'config/firebase';

import {
    addingNewGuestSuccess,
    addingNewGuestFailure,
    storeGuestsInvites,
    storeGuestsAccessKeys,
    storeOneTimeAccessCodes
} from '../actions/Guests';

import { generateInviteCode } from '../../utils/Helpers';

import { ACCESS_KEY_SCHEMA, GUEST_INVITE_SCHEMA } from '../../utils/constants';

import * as selectors from './Selectors';

import { eventChannel } from 'redux-saga';
import { getOrgById } from './Org';

import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';

const userCollectionRef = collection(db, 'users');
const guestInvitesCollectionRef = collection(db, 'guest_invites');
const accesKeysCollectionRef = collection(db, 'access_keys');
const otcCollectionRef = collection(db, 'otc');

function* listenToFirebaseChannel(
    query,
    channel,
    successAction,
    additionalDataProcessor = null
) {
    let unsubscribe;
    try {
        yield eventChannel(emit => {
            unsubscribe = onSnapshot(query, querySnapshot => {
                const data = [];
                querySnapshot.forEach(doc => {
                    data.push({ id: doc.id, ...doc.data() });
                });

                if (additionalDataProcessor) {
                    emit(additionalDataProcessor(data));
                } else {
                    emit(data);
                }
            });

            return unsubscribe;
        });

        while (true) {
            const { userSignOut, data } = yield race({
                userSignOut: take(LOGOUT_USER),
                data: take(channel)
            });

            if (userSignOut) {
                channel.close();
                break;
            } else {
                yield put(successAction(data));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
        if (unsubscribe) {
            unsubscribe();
        }
    }
}

export function* listenToAccessKeys(user) {
    const queryRef = query(
        accesKeysCollectionRef,
        where('creator_id', '==', user.uid),
        where('property_id', '==', user.active_property_id)
    );
    const accessKeysChannel = eventChannel(emit =>
        onSnapshot(queryRef, querySnapshot => {
            const data = [];
            querySnapshot.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            });
            emit(data);
        })
    );
    yield listenToFirebaseChannel(queryRef, accessKeysChannel, storeGuestsAccessKeys);
}

export function* listenToGuestInvites(user) {
    const queryRef = query(
        guestInvitesCollectionRef,
        where('creator_id', '==', user.uid),
        where('property_id', '==', user.active_property_id)
    );
    const guestInvitesChannel = eventChannel(emit =>
        onSnapshot(queryRef, querySnapshot => {
            const data = [];
            querySnapshot.forEach(doc => {
                data.push({ id: doc.id, isGuestInvite: true, ...doc.data() });
            });
            emit(data);
        })
    );

    yield listenToFirebaseChannel(
        queryRef,
        guestInvitesChannel,
        storeGuestsInvites,
        data => data.map(item => ({ ...item, isGuestInvite: true }))
    );
}

export function* listenToOneTimeAccessCodes(user) {
    const queryRef = query(otcCollectionRef, where('creator_id', '==', user.uid), where('property_id', '==', user.active_property_id));
    const otcChannel = eventChannel(emit =>
        onSnapshot(queryRef, querySnapshot => {
            const data = [];
            querySnapshot.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            });
            emit(data);
        })
    );

    yield listenToFirebaseChannel(queryRef, otcChannel, storeOneTimeAccessCodes);
}

export function* listenToAccessKeysAndGuestInvites() {
    const user = yield select(selectors._userData);
    try {
        yield race({
            accessKeys: listenToAccessKeys(user),
            guestInvites: listenToGuestInvites(user),
            oneTimeAccessKeys: listenToOneTimeAccessCodes(user),
            userSignOut: take(LOGOUT_USER)
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Guests ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const existingByEmail = async email => {
    const querySnapshot = await getDocs(
        query(userCollectionRef, where('email', '==', email))
    );

    if (!querySnapshot.size) {
        return [];
    }

    const emailExist = [];
    querySnapshot.forEach(doc => {
        emailExist.push(doc.data());
    });

    return emailExist;
};

const existingByPhone = async phone => {
    try {
        const querySnapshot = await getDocs(
            query(userCollectionRef, where('phone_number', '==', phone))
        );

        if (!querySnapshot.size) {
            return [];
        }

        const phoneExist = [];
        querySnapshot.forEach(doc => {
            phoneExist.push(doc.data());
        });

        return phoneExist;
    } catch (error) {
        console.error('Error checking for existing member:', error);
        return { error };
    }
};

const checkExistingGuestRequest = async member => {
    const { phone, email } = member;
    try {
        const emailExistence = email ? await existingByEmail(email) : [];
        const phoneExistence = phone
            ? await existingByPhone(`${phone.code}${phone.number}`)
            : [];

        const existence = [...emailExistence, ...phoneExistence];
        const filteredExistence = existence.reduce((reducedExistence, current) => {
            if (!reducedExistence.some(x => x.uid === current.uid)) {
                reducedExistence.push(current);
            }
            return reducedExistence;
        }, []);

        return { existing: filteredExistence };
    } catch (error) {
        return { error };
    }
};

export function* checkExistingGuest({ payload }) {
    const { member } = payload;
    try {
        const { existing } = yield call(() =>
            checkExistingGuestRequest({ email: member.email, phone: member.phone_number })
        );

        if (existing && existing.length) {
            return existing;
        } else {
            console.log('No existing members found');
            return [];
        }
    } catch (error) {
        console.error('Error checking for existing member:', error);
        return { error };
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add/Invite Guests ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addNewGuestsRequest = async ({ invite, org, user }) => {
    try {
        const data = GUEST_INVITE_SCHEMA;
        const firstName = invite.first_name.toLowerCase();
        const lastName = invite.last_name.toLowerCase();
        const email = invite.email ? invite.email.toLowerCase() : null;
        const phone = invite?.phone?.number ? invite.phone : null;

        const guestInviteKeyId =
            invite?.invite_id || doc(collection(db, 'guest_invites')).id;

        const inviteCode = generateInviteCode();
        const phoneNumber =
            invite?.phone?.code && invite?.phone?.number
                ? `${invite.phone.code}${invite.phone.number}`
                : null;

        const orgId = invite.orgId;
        const orgName = org.org_name;
        const now = serverTimestamp();

        const guestInviteData = {
            ...data,
            access_days: invite.access_days,
            access_end_time: invite.access_end_time,
            access_expires: null,
            access_start_time: invite.access_start_time,
            access_begins: now,
            access_created: null,
            address: invite?.address,
            creator_first_name: user.first_name,
            created_at: now,
            creator_id: user.uid,
            creator_last_name: user.last_name,
            email: email,
            first_name: firstName,
            invite_code: inviteCode,
            invite_id: guestInviteKeyId,
            invite_status: 'pending',
            invited_at: now,
            last_name: lastName,
            org_id: orgId,
            org_name: orgName,
            phone: phone,
            phone_number: phoneNumber,
            property_id: invite.property_id,
            role: 'guest',
            suspended: false,
            plates: [],
            type: 'short-term',
            vehicles: []
        };

        const guestInviteDocRef = doc(guestInvitesCollectionRef, guestInviteKeyId);
        if (invite?.invite_id) {
            await updateDoc(guestInviteDocRef, guestInviteData);
        } else {
            await setDoc(guestInviteDocRef, guestInviteData);
        }

        return { res: true };
    } catch (error) {
        throw new Error({
            error: `Error: ${
                invite?.invite_id ? 'updating' : 'creating'
            } guest invite - ${error.message}`
        });
    }
};

const addExistingGuestsRequest = async ({ member, user, org, exist }) => {
    try {
        const orgId = member.orgId;
        const accessKeyId = member.invite_id || doc(accesKeysCollectionRef).id;
        const data = ACCESS_KEY_SCHEMA;
        const tsNow = serverTimestamp();
        const orgName = org.org_name;
        let phoneNumber = null;

        if (exist?.phone?.number) {
            phoneNumber =
                exist?.phone?.code && exist?.phone?.number
                    ? `${exist.phone.code}${exist.phone.number}`
                    : null;
        } else {
            phoneNumber =
                member?.phone?.code && member?.phone?.number
                    ? `${member.phone.code}${member.phone.number}`
                    : null;
        }

        const phone = exist?.phone?.number
            ? exist.phone
            : member?.phone?.number
            ? member.phone
            : null;

        const accessKeyData = {
            ...data,
            access_begins: null,
            access_created: tsNow,
            access_days: member.access_days,
            access_end_time: member.access_end_time,
            access_expires: null,
            access_start_time: member.access_start_time,
            address: member.address,
            company_name: null,
            consumer_id: exist.uid,
            creator_first_name: user.first_name,
            creator_id: user.uid,
            creator_last_name: user.last_name,
            email: exist.email,
            favorite: false,
            first_name: exist.first_name,
            image: null,
            key_id: accessKeyId,
            last_name: exist.last_name,
            org_id: orgId,
            org_name: orgName,
            phone: phone,
            phone_number: phoneNumber,
            photo_id: null,
            property_id: member.property_id,
            plates: [],
            role: 'guest',
            suspended: false,
            validated: false,
            active: true,
            type: 'short-term',
            vehicles: []
        };

        const accessKeyDocRef = doc(accesKeysCollectionRef, accessKeyId);

        if (member.invite_id) {
            await updateDoc(accessKeyDocRef, accessKeyData);
        } else {
            await setDoc(accessKeyDocRef, accessKeyData);
        }

        return { res: true };
    } catch (error) {
        throw new Error({
            error: `Error: ${
                member.invite_id ? 'updating' : 'adding'
            } Firestore User Doc - ${error.message}`
        });
    }
};

export function* addGuests({ payload }) {
    const { member } = payload;
    const exist = yield call(() => checkExistingGuest({ payload }));

    const userData = yield select(selectors._userData);
    const orgData = yield getOrgById(member.orgId);

    try {
        let res, error;

        if (!exist || exist.length === 0) {
            ({ res, error } = yield call(() =>
                addNewGuestsRequest({ invite: member, org: orgData, user: userData })
            ));
        } else {
            ({ res, error } = yield call(() =>
                addExistingGuestsRequest({
                    member,
                    user: userData,
                    org: orgData,
                    exist: exist[0]
                })
            ));
        }

        if (res) {
            yield put(addingNewGuestSuccess());
        } else {
            yield put(addingNewGuestFailure(error));
        }
    } catch (error) {
        yield put(addingNewGuestFailure(error.error));
    }
}

export function* deleteGuest({ payload }) {
    const { guest } = payload;

    try {
        const collectionName = guest.isGuestInvite ? 'guest_invites' : 'access_keys';
        const guestDocRef = doc(collection(db, collectionName), guest.id);

        yield call(() => deleteDoc(guestDocRef));

        yield put({ type: REMOVE_GUEST_SUCCESS, payload: { guestId: guest.id } });
    } catch (error) {
        yield put({ type: REMOVE_GUEST_FAILURE, error });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Add One Time Access Code ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addingGuests() {
    yield takeLatest(ADD_NEW_GUEST, addGuests);
}

export function* deleteingGuests() {
    yield takeLatest(REMOVE_GUEST, deleteGuest);
}

export function* getGuestInvites() {
    yield takeLatest(GET_GUESTS, listenToAccessKeysAndGuestInvites);
}

export function* getGuestInvitesAfterActivePropertyChange() {
    yield takeLatest(SET_ACTIVE_USER_PROPERTY_SUCCESS, listenToAccessKeysAndGuestInvites);
}

export default function* rootSaga() {
    yield all([
        fork(deleteingGuests),
        fork(addingGuests),
        fork(getGuestInvites),
        fork(getGuestInvitesAfterActivePropertyChange)
    ]);
}
