/* eslint-disable react/no-array-index-key */
// React
import React, { useState, useEffect, useMemo, Fragment } from 'react';

// Assets
import { CalendarOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';

// Components
import { LprDetailsModal } from 'components';
import { TablePagination } from 'components/third-party/ReactTable';
import { PopupTransition } from 'components/@extended/Transitions';

// Packages
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    Button,
    Dialog,
    Typography,
    OutlinedInput,
    TableContainer,
    IconButton
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Utils
import { renderFilterTypes, DefaultColumnFilter } from 'utils/react-table';
import { tsFromJsDate } from 'utils/Helpers';

const LprTable = ({
    data,
    openSnackbar,
    orgId,
    queryLpr,
    loading,
    getLprCollection,
    executeLprQuery,
    fromDate,
    toDate
}) => {
    const [lpr, setLpr] = useState(null);
    const [open, setOpen] = useState(false);
    const [localFromDate, setLocalFromDate] = useState(
        fromDate?.toDate() || new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    );
    const [initial, setInitial] = useState(true);
    const [localToDate, setLocalToDate] = useState(toDate?.toDate() || new Date());
    const [plate, setPlate] = useState('');

    useEffect(() => {
        if (queryLpr) {
            getLprCollection({ plate });
        }
    }, [queryLpr, plate, getLprCollection]);

    const columns = useMemo(
        () => [
            {
                Header: 'Date/Time',
                accessor: `date_string`
            },
            {
                Header: 'Plate',
                accessor: 'plate',
                className: 'cell-center'
            },
            {
                Header: 'Country/State',
                accessor: 'state',
                className: 'cell-center'
            },
            {
                Header: 'Make',
                accessor: 'make',
                className: 'cell-center'
            },
            {
                Header: 'Model',
                accessor: 'model',
                className: 'cell-center'
            },
            {
                Header: 'Color',
                accessor: 'color',
                className: 'cell-center'
            },
            {
                Header: 'Panel',
                accessor: 'panel',
                className: 'cell-center'
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClick = ({ original }) => {
        setLpr({ ...original });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLpr(null);
    };

    const handleQuery = () => {
        setInitial(false);
        executeLprQuery({
            from: tsFromJsDate(localFromDate),
            to: tsFromJsDate(localToDate)
        });
    };

    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const initialState = useMemo(
        () => ({
            filters: [],
            pageIndex: 0,
            pageSize: 10
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            filterTypes
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
                width: '100%',
                overflow: 'hidden',
                justifyContent: 'space-between'
            }}
        >
            <Stack
                direction={matchDownSM ? 'column' : 'row'}
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 3, pb: 3 }}
            >
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                    <OutlinedInput
                        onChange={e => setPlate(e.target.value.toUpperCase())}
                        placeholder={'Search for a plate'}
                        id="lpr-search"
                        startAdornment={<SearchOutlined />}
                        value={plate}
                    />
                    {plate.trim() !== '' && (
                        <IconButton
                            size="medium"
                            color="secondary"
                            onClick={() => setPlate('')}
                        >
                            <CloseOutlined />
                        </IconButton>
                    )}
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography align="left" variant="subtitle1">
                        From:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            value={localFromDate}
                            onChange={newValue => setLocalFromDate(newValue)}
                            maxDateTime={localToDate}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>

                    <Typography align="left" variant="subtitle1">
                        To:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            value={localToDate}
                            onChange={newValue => setLocalToDate(newValue)}
                            minDateTime={localFromDate}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        disabled={loading || queryLpr}
                        startIcon={<CalendarOutlined />}
                        onClick={handleQuery}
                    >
                        Search
                    </Button>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    justifyContent: 'space-between'
                }}
            >
                <TableContainer sx={{ maxHeight: '100%' }}>
                    <Table {...getTableProps()}>
                        <TableHead sx={{ borderTopWidth: 1 }}>
                            {headerGroups.map((headerGroup, index) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={index}
                                >
                                    {headerGroup.headers.map((column, index) => (
                                        <TableCell
                                            {...column.getHeaderProps([
                                                { className: column.className },
                                                column => column.getSortByToggleProps()
                                            ])}
                                            sx={{
                                                width: 'auto'
                                            }}
                                            key={index}
                                        >
                                            {column.render('Header')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {data && data.length === 0 && !loading ? (
                                <TableRow colSpan="4">
                                    <TableCell>
                                        <Typography
                                            variant="h3"
                                            component="span"
                                            color="text.secondary"
                                            sx={{
                                                textTransform: 'capitalize',
                                                width: '100%'
                                            }}
                                        >
                                            {initial
                                                ? `Please enter your search criteria above`
                                                : `No plates matching query`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {headerGroups.map((group, i) => (
                                        <TableRow
                                            {...group.getHeaderGroupProps()}
                                            key={i}
                                        >
                                            {group.headers.map((column, index) => (
                                                <TableCell
                                                    {...column.getHeaderProps([
                                                        { className: column.className }
                                                    ])}
                                                    key={index}
                                                />
                                            ))}
                                        </TableRow>
                                    ))}
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Fragment key={i}>
                                                <TableRow
                                                    {...row.getRowProps()}
                                                    onClick={() => {
                                                        handleClick(row);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textTransform: 'capitalize',
                                                        bgcolor: row.isSelected
                                                            ? alpha(
                                                                  theme.palette.primary
                                                                      .lighter,
                                                                  0.35
                                                              )
                                                            : 'inherit'
                                                    }}
                                                    key={i}
                                                >
                                                    {row.cells.map((cell, i) => (
                                                        <TableCell
                                                            {...cell.getCellProps([
                                                                {
                                                                    className:
                                                                        cell.column
                                                                            .className
                                                                }
                                                            ])}
                                                            key={i}
                                                        >
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            <Stack sx={{ p: 2, bottom: 0, width: '100%' }} colSpan={7}>
                <TablePagination
                    gotoPage={gotoPage}
                    rows={rows}
                    setPageSize={setPageSize}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                />
            </Stack>
            <Dialog
                maxWidth="xl"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={() => setOpen(false)}
                open={open}
                sx={{
                    '& .MuiDialog-paper': { p: 0 },
                    transition: 'transform 225ms'
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                {open && <LprDetailsModal onClose={handleClose} lpr={lpr} />}
            </Dialog>
        </Stack>
    );
};

export default LprTable;
