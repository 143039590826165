import { timestampToDate } from '../Helpers';
import { DEVICE_STATUS } from '../constants/statuses';

export const calculateDeviceStatus = device => {
    const currentTime = new Date();

    if (device.disabled) {
        return DEVICE_STATUS.DISABLED;
    }

    if (device.activate_at && timestampToDate(device.activate_at) > currentTime) {
        return DEVICE_STATUS.INACTIVE;
    }

    if (device.expires_at && timestampToDate(device.expires_at) < currentTime) {
        return DEVICE_STATUS.EXPIRED;
    }

    return DEVICE_STATUS.ENABLED;
};
