// React
import React, { useState, useEffect, useCallback } from 'react';

// Assets
import { RightOutlined } from '@ant-design/icons';

// Packages
import {
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    OutlinedInput
} from '@mui/material';

const CallSearch = ({
    residents,
    getPropertyMembers,
    setPropertyAddress,
    changingFocus
}) => {
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [input, setSearchInput] = useState('');
    const [searchList, setSearchList] = useState([]);

    const formatSearchList = useCallback(({ residents, setSearchList }) => {
        const formattedList = [];
        residents.map(resident => {
            const properties = resident.properties
                ? Object.values(resident.properties)
                : null;
            if (properties) {
                properties.map(item => {
                    formattedList.push({
                        first_name: resident.first_name,
                        last_name: resident.last_name,
                        check_in_date: resident.check_in_date,
                        access_begins: resident.access_begins,
                        active: resident.active,
                        reservation_id: resident.reservation_id,
                        role: resident.role,
                        uid: resident.uid,
                        phone: resident.phone,
                        address: item.address,
                        validated: resident.validated,
                        property_id: item.id,
                        properties: properties
                    });
                    return item;
                });
            }
            return resident;
        });
        setSearchList(formattedList);
    }, []);

    useEffect(() => {
        formatSearchList({ residents, setSearchList: setSearchList });
    }, [residents, setSearchList, formatSearchList]);

    const handleSuggestionClick = ({ member }) => {
        setSearchSuggestions([]);
        setSearchInput('');
        getPropertyMembers({ propertyId: member.property_id });
        setPropertyAddress(member.address);
    };

    const _onResidentSearch = input => {
        setSearchInput(input);
        if (input.trim() !== '') {
            if (!isNaN(input[0]) || input.includes('-')) {
                // Address
                const filteredName = () => {
                    const nameSuggestions = [];
                    searchList.filter(
                        ({
                            first_name,
                            last_name,
                            check_in_date,
                            access_begins,
                            active,
                            reservation_id,
                            role,
                            uid,
                            phone,
                            address,
                            validated,
                            property_id,
                            properties
                        }) => {
                            const firstName = first_name;
                            const lastName = last_name;
                            if (
                                typeof address === 'string' &&
                                address.toLowerCase().includes(input.toLowerCase())
                            ) {
                                if (active || role === 'resi') {
                                    nameSuggestions.push({
                                        first_name: firstName,
                                        last_name: lastName,
                                        check_in_date: check_in_date || null,
                                        utc_seconds: access_begins?.seconds || null,
                                        id: reservation_id || `${uid}_${property_id}`,
                                        phone,
                                        address,
                                        validated,
                                        res: reservation_id ? true : false,
                                        properties,
                                        property_id
                                    });
                                }
                            }
                            return true;
                        }
                    );
                    return nameSuggestions;
                };

                const suggestions = filteredName();

                const sorted = suggestions.sort((a, b) => {
                    return a.utc_seconds - b.utc_seconds;
                });

                setSearchSuggestions(sorted);
            } else {
                // Last Name
                const filteredName = () => {
                    const nameSuggestions = [];
                    searchList.filter(
                        ({
                            first_name,
                            last_name,
                            check_in_date,
                            access_begins,
                            active,
                            reservation_id,
                            role,
                            uid,
                            phone,
                            address,
                            validated,
                            property_id,
                            properties
                        }) => {
                            const firstName = first_name;
                            const lastName = last_name;
                            if (lastName.toLowerCase().includes(input.toLowerCase())) {
                                if (active || role === 'resi') {
                                    nameSuggestions.push({
                                        first_name: firstName,
                                        last_name: lastName,
                                        check_in_date: check_in_date || null,
                                        utc_seconds: access_begins?.seconds || null,
                                        id: reservation_id || `${uid}_${property_id}`,
                                        phone,
                                        address,
                                        validated,
                                        res: reservation_id ? true : false,
                                        properties,
                                        property_id
                                    });
                                }
                            }
                            return true;
                        }
                    );
                    return nameSuggestions;
                };

                const suggestions = filteredName();

                const sorted = suggestions.sort((a, b) => {
                    return a.utc_seconds - b.utc_seconds;
                });

                setSearchSuggestions(sorted);
            }
        } else {
            setSearchSuggestions([]);
        }
    };

    const resetSearch = useCallback(() => {
        setSearchSuggestions([]);
        setSearchInput('');
    }, []);

    useEffect(() => {
        if (changingFocus) {
            resetSearch();
        }
    }, [changingFocus, resetSearch]);

    return (
        <Stack>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <OutlinedInput
                    inputProps={{
                        sx: {
                            height: '3vh',
                            backgroundColor: 'rgba(249, 249, 250, 1)',
                            borderRadius: '14px'
                        }
                    }}
                    type="search"
                    placeholder="Search by address or last name"
                    onChange={e => _onResidentSearch(e.target.value)}
                    value={input}
                />
            </Stack>
            <List
                sx={{
                    width: '100%',
                    overflowY: 'auto',
                    marginTop: '2.5vh',
                    maxHeight: '71.75vh'
                }}
            >
                {searchSuggestions.map(member => (
                    <ListItem
                        key={member.id}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '6.5vh',
                            width: '100%',
                            backgroundColor: '#ffffff',
                            borderRadius: '4px',
                            border: '1px solid rgba(243, 243, 243, 1)',
                            paddingX: '4%'
                        }}
                        button={true}
                        disableGutters
                        onClick={() => {
                            handleSuggestionClick({ member });
                        }}
                    >
                        <>
                            <ListItemText
                                sx={{
                                    maxWidth: '60%!important',
                                    left: '4%'
                                }}
                                primary={
                                    <Typography
                                        sx={{ display: 'block' }}
                                        component="span"
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {member.address}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            display: 'block',
                                            textTransform: 'capitalize'
                                        }}
                                        component="span"
                                        variant="body2"
                                        color="text.secondary"
                                    >{`${member.first_name} ${member.last_name}`}</Typography>
                                }
                            />
                            <ListItemIcon edge="end">
                                <RightOutlined
                                    style={{
                                        color: 'rgba(167, 167, 167, 1)',
                                        width: '4%'
                                    }}
                                />
                            </ListItemIcon>
                        </>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
};

export default CallSearch;
